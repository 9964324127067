<template>
  <v-card class="ml-0 ml-md-6 py-6 px-4 px-md-16 rounded-lg full-width" color="light_gray" tile elevation="0">
    <v-row align="center" no-gutters>
      <v-col cols="12" md="12" lg="10">
        <v-form ref="changePasswordForm">
          <v-row>
            <v-col cols="6">
              <span class="gray--text font-weight-bold text-body-1 text-capitalize">{{ $t(`label.changePassword`) }}</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="mr-4 pa-0" @click="submitChangePassword">
                <span class="unbox_primary--text text-capitalize font-weight-bold text-body-1 cursor-pointer">{{ $t(`label.update`) }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="8">
              <span class="d-block text-body-2 unbox_secondary--text mt-4">{{ $t(`label.changePasswordMessage`) }}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="12">
                  <app-form-field
                    v-model.trim="changePasswordForm.currentPassword"
                    :label="$t(`label.currentPassword`)"
                    type="password"
                    :placeholder="$t(`label.currentPassword`)"
                    :rules="$validation.passwordRules()"
                  ></app-form-field>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0"></v-col>
                <v-col cols="12">
                  <app-form-field
                    v-model.trim="changePasswordForm.newPassword"
                    :label="$t(`label.newPassword`)"
                    type="password"
                    :placeholder="$t(`label.newPassword`)"
                    :rules="$validation.passwordRules()"
                  ></app-form-field>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0"></v-col>
                <v-col cols="12">
                  <app-form-field
                    v-model.trim="changePasswordForm.newConfirmPassword"
                    :label="$t(`label.newConfirmPassword`)"
                    type="password"
                    :placeholder="$t(`label.newConfirmPassword`)"
                    :rules="$validation.confirmPasswordRules(changePasswordForm.newPassword, changePasswordForm.newConfirmPassword)"
                  ></app-form-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0"></v-col>

            <v-col cols="12" sm="6">
              <div>
                <div class="text-body-2 unbox_secondary--text">{{ $t(`label.createPasswordRequirementMessage`) }}</div>
                <div>
                  <ul>
                    <li :class="changePasswordForm.newPassword.length > 0 && changePasswordForm.newPassword.length >= 8 ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesMinimumLength') }}</span>
                    </li>
                    <li :class="changePasswordForm.newPassword.length > 0 && changePasswordForm.newPassword.length <= 20 ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesMaximumLength') }}</span>
                    </li>
                    <li :class="regex.ATLEAST_ONE_UPPERCASE_LETTER.test(changePasswordForm.newPassword) ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesUppercaseLetter') }}</span>
                    </li>
                    <li :class="regex.ATLEAST_ONE_LOWERCASE_LETTER.test(changePasswordForm.newPassword) ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesLowercaseLetter') }}</span>
                    </li>
                    <li :class="regex.ATLEAST_ONE_DIGIT.test(changePasswordForm.newPassword) ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesDigit') }}</span>
                    </li>
                    <li :class="regex.ATLEAST_ONE_SPECIAL_CHARACTER.test(changePasswordForm.newPassword) ? 'success--text' : 'error--text'">
                      <span class="text-body-2">{{ $t('validationMessage.passwordRulesSpecialCharacter') }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="pa-0 ma-0"></v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { REGEX } from '@/constants'
import { localeHelper } from '@/utils'
import { CUSTOMER_CHANGE_PASSWORD } from '@/store/customer.module'
import { SESSION } from '@/constants'
export default {
  name: 'changPassword',
  data: () => ({
    regex: REGEX,
    changePasswordForm: { currentPassword: '', newPassword: '', newConfirmPassword: '' }
  }),
  computed: {
    changePasswordResponseComplete() {
      return this.$store.state.customer.changePasswordResponse.complete
    }
  },
  created() {},
  watch: {
    changePasswordResponseComplete() {
      let response = this.$store.state.customer.changePasswordResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.changePasswordComplete`))
          this.$refs.changePasswordForm.reset()
        }
      }
    }
  },
  methods: {
    submitChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        let data = {
          uuid: localStorage.getItem(SESSION.CUSTOMER_UUID),
          currentPassword: this.changePasswordForm.currentPassword,
          newPassword: this.changePasswordForm.newPassword,
          newConfirmPassword: this.changePasswordForm.newConfirmPassword
        }
        this.$store.dispatch(CUSTOMER_CHANGE_PASSWORD, { data })
      }
    }
  }
}
</script>
